/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Poppins";
}
header {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
}
header a img.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
header a h1 {
  margin: 0;
  font-size: 1.5em;
}
nav {
  display: flex;
  align-items: center;
}
.nav-links {
  display: flex;
  align-items: center;
}
.nav-links a, .nav-links button {
  margin-left: 10px;
  font-size: 1em;
  text-decoration: none;
  font-family: "Poppins";
  color: #333;
}
.nav-links button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
}
.nav-links button:hover, .nav-links a:hover {
  background: var(--primary);
  color: #fff;
}
.pages {
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}

/* homepage */
.home {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 100px;
}
.workout-details {
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
}
.workout-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.workout-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
.workout-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

/* new workout form */
label, input {
  display: block;
}
input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}
input.error {
  border: 1px solid var(--error);
}

/* navbar */
nav {
  display: flex;
  align-items: center;
}
.nav-links {
  display: flex;
  align-items: center;
}
.nav-links a, .nav-links button {
  margin-left: 10px;
}
.nav-links button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  font-family: "Poppins";
  cursor: pointer;
  font-size: 1em;
}

/* Footer */
footer {
  background: #fff;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
  padding: 10px 20px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}
footer .container {
  max-width: 1400px;
  margin: 0 auto;
}
footer p {
  margin: 0;
  font-size: 0.9em;
  color: #333;
}
footer a {
  color: var(--primary);
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}

/* auth forms */
form.signup, form.login {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

/* Media Queries */
@media (max-width: 1200px) {
  header .container {
    flex-direction: column;
    align-items: flex-start;
  }
  .home {
    grid-template-columns: 1fr;
    gap: 50px;
  }
  .workout-details {
    margin: 10px auto;
  }
}

@media (max-width: 749px) {
  header .container {
    padding: 10px;
  }
  header a h1 {
    font-size: 1.2em;
  }
  .nav-links a, .nav-links button {
    margin-left: 5px;
    font-size: 0.9em;
  }
  footer {
    padding: 5px 10px;
  }
  footer p {
    font-size: 0.8em;
  }
}
